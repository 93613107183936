<template>
    <div class="row d-flex justify-content-center mb-5 mt-5">
        <div class="col-10 mb-5">
            <h1>Iscritti</h1>
        </div>
        
        <div class="col-10" >
            <div>
                <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Targa</th>
                        <th scope="col">Modello</th>
                        <th scope="col">Guidatori</th>
                        <th scope="col">Gran Prix</th>
                        <th scope="col">Truck Pulling</th>
                        <th scope="col">Best Truck</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(truck, index) in this.trucks" :key="index">
                        <th scope="row">{{truck.id}}</th>
                        <td>{{truck.registration}}</td>
                        <td>{{truck.model}}</td>
                        <td>{{truck.competitors}}</td>
                        <td>{{truck.gPrix}}</td>
                        <td>{{truck.tPulling}}</td>
                        <td>{{truck.bTruck}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import API from "@/api.js";


export default {
components: { },

  data () {
    return {
       
      trucks:[
      ],
    }
  },
  mounted() {
    // Set the initial number of items

    this.axios.get(API.getTrucks.path()).then(response => {
      this.trucks=response.data;
      console.log(response)

    }).catch(error=>{
      console.log(error)

    });

  },
}



</script>
